import React from "react";
import ClientRepository from "../../repository/ClientRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import ClientTextFormComponent from "./forms/ClientTextFormComponent";
import Module from "../../models/module/Module";
import Client from "../../models/client/Client";
import { ModuleRepository, WebsiteRepository } from "../../repository";
import { LoaderComponent } from "../basics/layout";
import Website from "../../models/website/Website";
import { CountryHelper } from "../../helpers";
import * as Yup from "yup"
import ClientHelper from "../../helpers/ClientHelper";

class ClientFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new Client(),
            loading: true,
            settings: {},
            isEdit: false,
            roles: this.props.user.roles,
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings(),
            WebsiteRepository.get(Website.get())
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                let model = this.state.model;
                if (result[2] !== undefined) {
                    model = Client.fromJson(result[2].client);
                }

                if (result[1] !== undefined) {
                    if (!model.language) model.language = result[1].default_language;

                    let languages = [];
                    result[1].languages.forEach(language => {
                        languages.push({ value: language.language, label: CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label })
                    });
                    settings.languages = languages;
                }

                this.setState({
                    loading: false,
                    settings: settings,
                    model: model,
                    isEdit: model.id
                });
            });
    }

    schema = () => {
        let { settings, model } = this.state;

        let fields = {
            language: Yup.string().required('CMS.Client.Content.Form.language'),
            first_name: Yup.string().required('CMS.Client.Content.Form.first_name').max(100, 'CMS.Client.Content.Form.first_name|100|max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'CMS.Client.Content.Form.first_name|letter', { excludeEmptyString: true }),
            last_name: Yup.string().required('CMS.Client.Content.Form.last_name').max(100, 'CMS.Client.Content.Form.last_name|100|max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'CMS.Client.Content.Form.first_name|letter', { excludeEmptyString: true }),
            email: Yup.string().required('CMS.Client.Content.Form.email').email('CMS.Client.Content.Form.email|invalidemail').max(100, 'CMS.Client.Content.Form.email|100|max')
        };

        if (settings.fields?.birth_date) {
            fields.birth_date = Yup.date().max(new Date(), 'CMS.Client.Content.Form.birth_date|max_date').nullable();
        }
        if (settings.fields?.national_insurance_number) {
            fields.national_insurance_number = Yup.string().max(50, 'CMS.Client.Content.Form.national_insurance_number|50|max').test(
                'is-nin-valid',
                'CMS.Client.Content.Form.national_insurance_number|invalid',
                (value, context) => ClientHelper.validateNationalInsuranceNumber(value)
            ).nullable();
        }
        if (settings.fields?.phone) {
            fields.phone = Yup.string().phone('BE', 'CMS.Client.Content.Form.phone|phone').nullable();
        }

        if (settings.fields?.billing_address) {
            fields.billing_street = Yup.string().matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'CMS.Client.Content.Form.billing_street|match', { excludeEmptyString: true }).max(100, 'CMS.Client.Content.Form.billing_street|100|max').nullable();
            fields.billing_number = Yup.string().matches(/^[a-zA-Z0-9'-.säÄëËüÜïÏöÖ ]*$/, 'CMS.Client.Content.Form.billing_number|match', { excludeEmptyString: true }).max(100, 'CMS.Client.Content.Form.billing_number|100|max').nullable();
            fields.billing_box = Yup.string().max(100, 'CMS.Client.Content.Form.billing_box|100|max').nullable();
            fields.billing_postal = Yup.string().matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'CMS.Client.Content.Form.billing_postal|match', { excludeEmptyString: true }).max(50, 'CMS.Client.Content.Form.billing_postal|50|max').nullable();
            fields.billing_city = Yup.string().matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'CMS.Client.Content.Form.billing_city|match', { excludeEmptyString: true }).max(100, 'CMS.Client.Content.Form.billing_city|100|max').nullable();
        }

        if (settings.fields?.delivery_address && !model.delivery_equal) {
            fields.delivery_street = Yup.string().matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'CMS.Client.Content.Form.delivery_street|match', { excludeEmptyString: true }).max(100, 'CMS.Client.Content.Form.delivery_street|100|max').nullable();
            fields.delivery_number = Yup.string().matches(/^[a-zA-Z0-9'-.säÄëËüÜïÏöÖ ]*$/, 'CMS.Client.Content.Form.delivery_number|match', { excludeEmptyString: true }).max(100, 'CMS.Client.Content.Form.delivery_number|100|max').nullable();
            fields.delivery_box = Yup.string().max(100, 'CMS.Client.Content.Form.delivery_box|100|max').nullable();
            fields.delivery_postal = Yup.string().matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'CMS.Client.Content.Form.delivery_postal|match', { excludeEmptyString: true }).max(50, 'CMS.Client.Content.Form.delivery_postal|50|max').nullable();
            fields.delivery_city = Yup.string().matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'CMS.Client.Content.Form.delivery_city|match', { excludeEmptyString: true }).max(100, 'CMS.Client.Content.Form.delivery_city|100|max').nullable();
        }

        return Yup.object().shape(fields);
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className={"client"}
                validationSchema={this.schema}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    if (this.state.isEdit) {
                        ClientRepository.update({ id: values.id, ...values })
                            .then(() => {
                                FormToastComponent.successTrans("Client", "Default.saved");
                                this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    } else {
                        ClientRepository.create({ ...values, ...{ source: 'console' } })
                            .then(() => {
                                FormToastComponent.successTrans("Client", "Default.saved");
                                this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    }
                }}
                formErrors={this.state.form_errors}
                fields={ClientTextFormComponent.fields(this.state.settings, this)}
            />
        );
    }
}

export default withRouter(ClientFormComponent);