import React from "react";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import PropTypes from "prop-types";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import FormattedMessageString from "../FormattedMessageString";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import Application from "../../../helpers/Application";
const { SearchBar } = Search;

class StandardTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();

        this.options = this.props.customOptions !== undefined ?
            this.props.customOptions :
            {
                sizePerPage: Application.setting('table_number_rows', 100),
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true,
                custom: true,
                totalSize: this.props.data.length,
                prePageText: <FormattedMessageString id="CMS.Table.Pagination.prev" />,
                nextPageText: <FormattedMessageString id="CMS.Table.Pagination.next" />,
                page: Application.tablePageNumber(),
                onPageChange: (page) => Application.updateTablePageNumber(page)
            };
    }

    rowClasses = (row, rowIndex) => {
        let classes = '';

        if (this.props.draggable) {
            classes += 'row-draggable ';
        }

        if (this.props.subRows) {
            if (row.index !== undefined) {
                if (row.index > 0) {
                    classes += "sub-row sub-row-placeholder ";
                    if (row.index === 1) {
                        classes += "one ";
                    }
                    if (row.index === 2) {
                        classes += "two "
                    }
                } else {
                    classes += "main-row"
                }
            }
        }

        return classes;
    };

    componentDidMount() {
        if (this.props.draggable) {
            let rows = document.getElementsByClassName('row-draggable');

            for (let i = 0; i < rows.length; i++) {
                let row = rows[i];
                row.setAttribute('draggable', true)
                row.addEventListener('dragstart', this.dragStart.bind(this), false);
                row.addEventListener('dragover', this.dragOver.bind(this), false);
            }
        }

        this.fixSubRows();

        let pageLinks = document.getElementsByClassName('page-link');
        for (let i = 0; i <= pageLinks.length - 1; i++) {
            pageLinks[i].addEventListener('click', this.initPageChangeListener);
        }

        if (this.props.search) {
            document.getElementById('search-bar-0').addEventListener('click', this.beforeSearch);
        }
    }

    componentWillUnmount() {
        let pageLinks = document.getElementsByClassName('page-link');
        for (let i = 0; i <= pageLinks.length - 1; i++) {
            pageLinks[i].removeEventListener('click', this.initPageChangeListener);
        }

        if (this.props.search) {
            document.getElementById('search-bar-0').removeEventListener('click', this.beforeSearch);
        }
    }

    initPageChangeListener = () => setTimeout(() => this.fixSubRows(), 100);

    fixSubRows = () => {
        if (!this.props.subRows) {
            return;
        }

        let tables = document.getElementsByClassName("react-bootstrap-table");
        for (let i = 0; i < tables.length; i++) {
            let table = tables[i];

            let firstColumnHead = table.getElementsByTagName('th')[1];
            if (firstColumnHead !== undefined) {
                firstColumnHead.setAttribute('colspan', 3);
            }

            let mainRows = table.getElementsByClassName('main-row');
            for (let i = 0; i < mainRows.length; i++) {
                let mainRow = mainRows[i];
                let firstColumn = mainRow.getElementsByTagName('td')[0];
                let secondColumn = mainRow.getElementsByTagName('td')[1];

                if (firstColumn !== undefined) {
                    firstColumn.setAttribute('colspan', 1);
                    firstColumn.classList.remove('main-row');
                    firstColumn.style.width = '0px';
                    //firstColumn.style.padding = '0px';
                    firstColumn.style.padding = '0.75rem';
                }

                if (secondColumn !== undefined) {
                    secondColumn.setAttribute('colspan', 3);
                }
            }

            let subRows = table.getElementsByClassName('sub-row-placeholder');

            for (let i = 0; i < subRows.length; i++) {
                let subRow = subRows[i];
                let firstColumn = subRow.getElementsByTagName('td')[0];
                let secondColumn = subRow.getElementsByTagName('td')[1];


                if (subRow.classList.contains('one')) {
                    if (firstColumn !== undefined) {
                        firstColumn.setAttribute('colspan', 2);
                        firstColumn.style.width = '0px';
                        firstColumn.style.padding = '0px';
                        firstColumn.style.background = '#fff';
                    }
                    if (secondColumn !== undefined) {
                        secondColumn.setAttribute('colspan', 2);
                    }
                } else {
                    if (firstColumn !== undefined) {
                        firstColumn.setAttribute('colspan', 3);
                        firstColumn.style.width = '100px';
                        firstColumn.style.padding = '0px';
                        firstColumn.style.background = '#fff';
                    }
                }
            }

            while (subRows.length > 0) {
                let subRow = subRows[0];
                subRow.classList.remove('sub-row-placeholder');
            }
        }
    }

    _current = null;
    dragStart = (e) => this._current = e.target;

    dragOver = (e) => {
        e.preventDefault();
        let children = Array.from(e.target.parentNode.parentNode.children);
        let target = e.target;
        if (target.nodeName !== 'TR') {
            target = target.parentNode;
        }
        if (target.nodeName === 'TR') {
            if (children.indexOf(target) > children.indexOf(this._current))
                target.after(this._current);
            else target.before(this._current);
        }
    }

    beforeSearch = (e) => {
        Application.updateTablePageNumber(1);
    }

    render() {
        let totalPages = Math.ceil(this.options.totalSize / this.options.sizePerPage);

        this.options.page = Application.tablePageNumber();
        if (totalPages < this.options.page) {
            this.options.page = 1;
        }

        return (
            <ToolkitProvider
                {...this.props}
                bootstrap4
                columnToggle
                search={this.props.search ? { searchFormatted: true, afterSearch: () => setTimeout(() => this.fixSubRows(), 100) } : false}
            >
                {
                    props => (
                        <PaginationProvider pagination={paginationFactory(this.options)}>
                            {({ paginationProps, paginationTableProps }) => (
                                <div className="table-wrapper">
                                    <div className={"table-header"}>
                                        <FormattedMessage id={this.props.title}>
                                            {(title) =>
                                                <FormattedMessage id={"CMS.Table.overview"}>
                                                    {(title2) => <p>{title + " " + title2}</p>}
                                                </FormattedMessage>
                                            }
                                        </FormattedMessage>
                                        <div className="d-flex">
                                            {this.props.search && (
                                                <div className={"custom-search-bar mr-4"}>
                                                    <FontAwesomeIcon icon={['fas', 'search']} />
                                                    <FormattedMessage id={this.props.title}>
                                                        {title =>
                                                            <FormattedMessage id="CMS.Table.search.placeholder" values={{ type: title }}>
                                                                {value => <SearchBar {...props.searchProps} placeholder={value} />}
                                                            </FormattedMessage>
                                                        }
                                                    </FormattedMessage>

                                                </div>
                                            )}
                                            {this.props.custom}
                                        </div>
                                    </div>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        {...paginationTableProps}
                                        rowStyle={this.props.rowStyle}
                                        wrapperClasses="table-responsive"
                                        ref={this.tableRef}
                                        rowClasses={this.rowClasses}
                                        cellEdit={this.props.cellEdit}
                                        sort={this.props.sort}
                                        defaultSorted={this.props.defaultSorted}
                                    />
                                    <PaginationListStandalone {...paginationProps} />
                                </div>
                            )}
                        </PaginationProvider>
                    )
                }
            </ToolkitProvider>
        );
    }
}

StandardTableComponent.propTypes = {
    keyField: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
    subRows: PropTypes.bool,
    search: PropTypes.bool,
    customOptions: PropTypes.array,
    custom: PropTypes.any
};
export default StandardTableComponent
