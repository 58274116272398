import EventPeriod from "../models/event/EventPeriod";
import API from "../services/api";

export default class EventPeriodRepository {
    static all = (event = null) => API.get('/event/period/all?event=' + event).then(response => response.data.periods.map(period => EventPeriod.fromJson(period.period)));
    static get = (id, event) => API.get('/event/period/details/' + id + '?event=' + event).then(response => EventPeriod.fromJson(response.data.period));

    static create = (data) => API.post('/event/period/create', data);
    static update = (data) => API.post('/event/period/update', data);
    static quickUpdate = (data) => API.post('/event/period/quick-update', data).then(response => EventPeriod.fromJson(response.data.period));
    static duplicate = (data) => API.post('/event/period/duplicate', data).then(response => EventPeriod.fromJson(response.data.period));
    static delete = (data) => API.delete('/event/period/delete?id=' + data.id + '&event=' + data.event);
    static toggle = (data) => API.post('/event/period/toggle', data)
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });
}
